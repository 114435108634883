// App.js
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import logo from './images/wbsHorizontal.png';
import logoNavbar from './images/wbSmall.png';
import logoW from './images/w.png';
import circle from './images/circle.gif';
import circle2 from './images/circle.png';
import costaricanFlag from './images/costa-rica.png';
import mail from './images/GX_mail.png';
import whats from './images/GX_whats.png';
import square from './images/wbs-square.png';
import axios from 'axios';

function App() {

  // ----------------- VARIABLES & STATES -----------------

  const [animate, setAnimate] = useState(false);
  const [showNavbarLogo, setShowNavbarLogo] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [navbarFixed, setNavbarFixed] = useState(false);
  const formRef = useRef(null);
  const contactsRef = useRef(null);

  // ----------------- ANIMATIONS -----------------

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500); // 0.5 segundos

    return () => clearTimeout(timer);
  }, []);

  const handleAnimationEnd = () => {
    setShowNavbarLogo(true);
    setNavbarFixed(true);
    scrollToTop();
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // ----------------- CONTACT FORM SUBMIT -----------------
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      firstName: document.getElementById('name').value,
      lastName: document.getElementById('lastName').value,
      from: document.getElementById('email').value,
      message: document.getElementById('message').value
    };

    try {
      const response = await axios.post('https://sendemail-ohd4t4hbnq-uc.a.run.app', formData);
      console.log(response.data);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email. Please try again later.');
    }
  };


  // --------------------------- JUMPS ---------------------------
  const handleJumpClickForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleJumpClickContacts = () => {
    if (contactsRef.current) {
      contactsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  // codigo para el gif
  // <img className="circle-right" src={circle} alt="circle"></img>

  return (

    <div className="app">
      {/* --------------------------- NAV BAR & LOGO  --------------------------- */}

      <div className={`navbar ${navbarFixed ? 'fixed-navbar' : ''}`}>
        <img
          className={`fullscreen-title ${animate ? 'title-to-navbar' : ''}`}
          src={showNavbarLogo ? logoNavbar : logo}
          alt="Logo We Build"
          onAnimationEnd={handleAnimationEnd}
        />
      </div>

      {/* --------------------------- LANDING TEXT & LOGO  --------------------------- */}

      <div className="content-container">
        <img
          className={`landing-page w-logo ${showContent ? 'show-content' : ''}`}
          src={logoW}
          alt="Logo W"
        />
        <div className={`title-container ${showContent ? 'show-content' : ''}`}>
          <span className="title-webuild"><span className='bold-title-webuild break-on-mobile'>we build</span>just right, </span>
          <span className="title-text">just for you</span>
        </div>


      </div>

      {/* --------------------------- INITIATIVE  --------------------------- */}

      <div className={`initiative-container ${showContent ? 'show-content' : ''}`}>
        <span className="initiative-label">initiative</span>
        <p>Many companies face the challenge of developing innovative and standout digital solutions, that resonate with their target audience and leave a lasting impression. </p>
        <p>This challenge often arises from the complexity and competitiveness of the digital landscape. </p>
        <p>The need to modernize and improve speed forces us to standardize processes on what commercial applications offer or take too long to adapt to it, thus losing the essential reason that has led the brand or company to succeed in the first place.</p>
      </div>

      {/* --------------------------- CIRCLE  --------------------------- */}
      <img
        className='circle-left'
        src={circle2}
        alt="circle"
      />
      {/* --------------------------- TEXT  --------------------------- */}

      <div className={`text-container ${showContent ? 'show-content' : ''}`}>
        <p>we can provide a customized web and mobile development solutions to help you overcome these challenges. </p>
      </div>
      <div className={`text-container2 ${showContent ? 'show-content' : ''}`}>
        <p>with a unique combination of technological prowess and creative expertise, our team creates digital experiences that not only captivate visually, but also deliver tangible results.</p>
        <p>this will enhance your company’s digital presence, reach new customers and deliver memorable experiences that reinforce their brand and increase revenue.</p>
        <p>improve your processes to suit your real needs.</p>
      </div>

      {/* --------------------------- BASED IN  --------------------------- */}

      <div className={`boxes ${showContent ? 'show-content' : ''}`}>
        <div className='box'>
          <div className="box-content centeredText">
            we're here to build memorable experiences (the good kind).
          </div>
        </div>

        <div className='box centeredBasedIn'>
          <div className="box-header">
            <img src={costaricanFlag} alt="Costa Rica Flag" className="flag-icon"></img>
            based in costa rica
          </div>
          <div className="box-content">
            webuild is born with a burning desire to challenge the status quo of what we know.
            through efficient web and mobile applications, our custom-made creations are meant for you and you only.
          </div>
        </div>
      </div>

      {/* --------------------------- CIRCLE  --------------------------- */}
      <img
        className='circle-right'
        src={circle2}
        alt="circle"
      />

      {/* --------------------------- CONTACT BUTTON  --------------------------- */}

      <div className={`contactBtn-container ${showContent ? 'show-content' : ''}`}>
        <img
          className='circle-aroundBtn'
          src={circle2}
          alt="circle"
        />
        <p>create the opportunity</p>
        <p>leverage any challenge</p>
        <button className="contact-button1" onClick={handleJumpClickForm}>now</button>
      </div>

      {/* --------------------------- CONTACT FORM  --------------------------- */}

      <div className={`contactBtn-container extraMargin ${showContent ? 'show-content' : ''}`}>
        <button className="contacts" onClick={handleJumpClickContacts}>contact</button>
      </div>

      <div ref={formRef} className={`contact-form-container ${showContent ? 'show-content' : ''}`}>
        <h1>Contact us</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div className="input-group">
              <label for="name">Name</label>
              <input type="text" id="name" placeholder="Name"></input>
            </div>
            <div className="input-group">
              <label for="lastName">Last Name</label>
              <input type="text" id="lastName" placeholder="Last Name"></input>
            </div>
          </div>
          <div className="input-group">
            <label for="email">E-mail</label>
            <input type="text" id="email" placeholder="E-mail"></input>
          </div>
          <div className="input-group">
            <label for="message">Message</label>
            <textarea className="messageContainer" type="text" id="message" placeholder="Message"></textarea >
          </div>
          <button className="contact-button2">send message</button>
        </form>
      </div>
      <div ref={contactsRef} className={`contacts-container ${showContent ? 'show-content' : ''}`}>
        <h1>Our contacts</h1>
        <p><img src={mail}></img>jfrojas@we-build.pro <img src={whats}></img>+506 8703-3380</p>
        <p></p>
      </div>

      {/* --------------------------- FOOTER  --------------------------- */}
      <div className={`footer-container ${showContent ? 'show-content' : ''}`}>
        <img
          className='circle-footer'
          src={circle2}
          alt="circle"
        />
        <img src={square} className='footer-img'></img>
      </div>
    </div >

  );
}

export default App;
